<template>
  <v-responsive>
    <SubMenuLottowinMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuLottowinMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>

          <SubMenuLottowin></SubMenuLottowin>
              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
                     <GlobalSideBannerBottom></GlobalSideBannerBottom>
                </v-col>
              </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>
          <v-row class="my-3 mx-0" no-gutters style="background: #fff">
            <v-col>
                  <GlobalSubBannerTop></GlobalSubBannerTop>
            </v-col>
          </v-row>
          <v-sheet min-height="53vh">
            <!--  -->


  
          <v-responsive class="mt-10">
              <h3> 당첨조회</h3>
              <p>구매한 로또번호를 입력하시면 역대 로또 당첨번호를 한번에 조회합니다.
</p>
              <v-card
                class="d-flex justify-center mt-1 mb-6 mx-auto"
                flat
                max-width="300"
              >

                    <v-text-field
                    class="ma-1 no-counter input-center"
                      label=""
                      outlined
                      style="font-size:26px;"
                              
                      :min="1"
                      :max="45" :length="2"
                      hide-details
                      single-line
                      type="number"
                      v-model="inputNum1"
                    ></v-text-field>
                    
                    <v-text-field
                    class="ma-1 no-counter input-center"
                      label=""
                      outlined
                      style="font-size:26px;"
                              
                      :min="1"
                      :max="45"
                      hide-details
                      single-line
                      type="number"
                      v-model="inputNum2"
                    ></v-text-field>
                    <v-text-field
                    class="ma-1 no-counter input-center"
                      label=""
                      outlined
                      style="font-size:26px;"
                              
                      :min="1"
                      :max="45"
                      hide-details
                      single-line
                      type="number"
                      v-model="inputNum3"
                    ></v-text-field>
                    <v-text-field
                    class="ma-1 no-counter input-center"
                      label=""
                      outlined
                      style="font-size:26px;"
                              
                      :min="1"
                      :max="45"
                      hide-details
                      single-line
                      type="number"
                      v-model="inputNum4"
                    ></v-text-field>
                    <v-text-field
                    class="ma-1 no-counter input-center"
                      label=""
                      outlined
                      style="font-size:26px;"
                              
                      :min="1"
                      :max="45"
                      hide-details
                      single-line
                      type="number"
                      v-model="inputNum5"
                    ></v-text-field>
                    <v-text-field
                    class="ma-1 no-counter input-center"
                      label=""
                      outlined
                      style="font-size:26px;"
                              
                      :min="1"
                      :max="45"
                      hide-details
                      single-line
                      type="number"
                      v-model="inputNum6"
                    ></v-text-field>
              </v-card>
              <div style="text-align:center;">
                  <v-btn
                    x-large
                    color="blue darken-4"
                    dark
                    @click="btn_LottoWinCheck"
                  >
                   당첨결과 조회하기
                  </v-btn>
              </div>
          </v-responsive>



        
          <v-responsive class="mt-10">
              <h3>역대 당첨횟수</h3>
              <v-sheet outlined class=" mt-1 " v-if="items">
                  <v-simple-table >
                    <template >
                      <thead>
                        <tr>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            1등
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            2등
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            3등
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            4등
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            5등
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="items.length > 1">
                        <tr
                        >
                          <td class="text-center">{{ lottoWinCheck.is_won6 }}</td>
                          <td class="text-center">{{ lottoWinCheck.is_won5nbonus }}</td>
                          <td class="text-center">{{ lottoWinCheck.is_won5 }}</td>
                          <td class="text-center">{{ lottoWinCheck.is_won4 }}</td>
                          <td class="text-center">{{ lottoWinCheck.is_won3 }}</td>
                          
                        </tr>
                      </tbody>

                      <tbody v-if="items.length < 1">
                        <tr
                        >
                          <td class="text-center">0</td>
                          <td class="text-center">0</td>
                          <td class="text-center">0</td>
                          <td class="text-center">0</td>
                          <td class="text-center">0</td>
                          
                        </tr>
                      </tbody>


                    </template>
                  </v-simple-table>
              </v-sheet>
              <v-sheet outlined class=" mt-1 " v-if="!items">
                  <v-simple-table >
                    <template >
                      <thead>
                        <tr>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            1등
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            2등
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            3등
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            4등
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            5등
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                        >
                          <td class="text-center">0</td>
                          <td class="text-center">0</td>
                          <td class="text-center">0</td>
                          <td class="text-center">0</td>
                          <td class="text-center">0</td>
                          
                        </tr>
                      </tbody>


                    </template>
                  </v-simple-table>
              </v-sheet>
          </v-responsive>
        
          <v-responsive class="mt-10" >
              <h3>역대 당첨조회</h3>
              <v-sheet outlined class=" mt-1 "  v-if="items">
                  <v-simple-table>
                    <template >
                      <thead>
                        <tr>
                          <th class="text-left datatable_header" style="font-size:13px;">
                            회차
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            당첨조회
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            등수
                          </th>
                        </tr>
                      </thead>

                      <tbody v-if="items.length > 1">
                        <tr
                          v-for="item in items"
                          :key="item.drwNo"
                        >
                          <td class="text-left">{{ item.drwNo }}</td>
                          <td class="text-center">
                            
                            <WidgetLottoNumber
                            :number="item.nums"  style="text-align:center;" viewtype="small"></WidgetLottoNumber>


                          </td>
                          <td class="text-center">{{ item.grade }}</td>
                        </tr>
                      </tbody>


                      <tbody v-if="items.length < 1">
                        <tr
                        >
                          <td class="text-center" colspan="5">
                            
                            <v-responsive class="my-5">
                            당첨된 번호조합이 없습니다.<br>                    
                            
                            <v-btn
                              color="blue darken-4 white--text mt-2"
                            to="/analysis/auto"
                            >
                              오늘의 행운번호 20수 받기
                            </v-btn>
                            </v-responsive>


                          </td>
                          
                        </tr>
                      </tbody>

                    </template>
                  </v-simple-table>
              </v-sheet>
              <v-sheet outlined class=" mt-1 "  v-if="!items">
                  <v-simple-table>
                    <template >
                      <thead>
                        <tr>
                          <th class="text-left datatable_header" style="font-size:13px;">
                            회차
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            당첨조회
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            등수
                          </th>
                        </tr>
                      </thead>



                      <tbody>
                        <tr
                        >
                          <td class="text-center" colspan="5">
                            
                            <v-responsive class="my-5">
                            당첨된 번호조합이 없습니다.<br>                    
                            
                            <v-btn
                              color="blue darken-4 white--text mt-2"
                            to="/analysis/auto"
                            >
                              오늘의 행운번호 20수 받기
                            </v-btn>
                            </v-responsive>


                          </td>
                          
                        </tr>
                      </tbody>

                    </template>
                  </v-simple-table>
              </v-sheet>
          </v-responsive>


              <v-row class="my-3 mx-0" no-gutters style="background: #fff">
                <v-col>
                     <GlobalSubBannerBottom></GlobalSubBannerBottom>
                </v-col>
              </v-row>



          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";



export default {
  name: "LottoWin",

  components: {
    //HelloWorld,
  },
  data: () => ({
    selectedItem: 0,
    breadcrumbs: [
      { text: "홈" },
      { text: "당첨결과 분석" },
      { text: "당첨결과 조회" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],

    
        freeboard: [
          {
            idx: 975,
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            numbers: '1613373840+9',
            grade: '5',
          },
        ],


        lottoWinCheck:null,
        items: null,


        inputNum1:null,
        inputNum2:null,
        inputNum3:null,
        inputNum4:null,
        inputNum5:null,
        inputNum6:null,


  }),


  watch: {
  },

  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getLottoWinCheck('');
  },
  methods: {
    maxLengthCheck() {
      /*
        console.log(object.max);
      if (object.value > JSON.parse(object.max)){
        object.value = 45;
      }
      */

//javascript: if (this.value > JSON.parse(this.value)) this.value = this.value.slice(0, this.maxLength);

    },

    btn_LottoWinCheck(){

      let numbersStr = '';

        if(
              this.$nvl(this.inputNum1)  
              && this.$nvl(this.inputNum2)
              && this.$nvl(this.inputNum3)
              && this.$nvl(this.inputNum4)
              && this.$nvl(this.inputNum5) 
              && this.$nvl(this.inputNum6)
           ){
          numbersStr = 
            this.inputNum1 + ',' +
            this.inputNum2 + ',' +
            this.inputNum3 + ',' +
            this.inputNum4 + ',' +
            this.inputNum5 + ',' +
            this.inputNum6;
            this.getLottoWinCheck(numbersStr);
        }else{
          alert('조회할 번호를 모두 입력해주세요.');

        }




      
        //
    },


    getLottoWinCheck(numbersStr) {


      this.$axios
        .get(
          "//lotto7777.iwinv.net/lottoapp/?mode=check&numbers="+numbersStr
        )
        .then((res) => {
          console.log(res);

          this.lottoWinCheck = res.data;
          this.items = this.lottoWinCheck.wons;

/*
          this.lottoNumbers = [
            this.latestLottoData['drwtNo1'],
            this.latestLottoData['drwtNo2'],
            this.latestLottoData['drwtNo3'],
            this.latestLottoData['drwtNo4'],
            this.latestLottoData['drwtNo5'],
            this.latestLottoData['drwtNo6']
            ];

          this.lottoBonus = this.latestLottoData['bnusNo'];
*/


        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },



  }
};
</script>
<style></style>
